import React from "react"

import Layout from "../components/Layout"
import Head from "../components/Head"

const NotFound = () => {
  return (
    <Layout>
      <Head title="Page not found"/>
        <div style={{ minHeight: "27.5vh" }}>
          <h1 className="title">Ooops... Page not found</h1>
        </div>
      
    </Layout>
  )
}

export default NotFound
